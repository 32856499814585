export const Bio = {
  name: "Juan Cruz",
  roles: ["Backend Developer", ".NET Developer", "Programmer"],
  description:
    "Desarrollador backend especializado en .NET",
  github: "https://github.com/jcpereyra97",
  resume:"https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/Document%2F.NET%20Pereyra%202024.pdf?alt=media&token=70319dc7-0965-4e36-a91f-02d217fdc647",
  linkedin: "https://www.linkedin.com/in/juancpereyra/",
};

export const skills = [  
  {
    title: "Backend",
    skills: [
      {
        name: "C#",
        image: "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/csharp-logo-265a149e.svg?alt=media&token=6a3c9ae1-4bd4-4346-ab7c-83bb8e1ed0f2",
      },
      {
        name: "C",
        image:
          "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/c-1.svg?alt=media&token=6c230019-e237-4df4-8ae3-111f22a520a6",
      },
      {
        name: "Python",
        image: "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/python.svg?alt=media&token=c52df30e-f485-4b61-8686-e8e35e0014f3",
      }, 
      {
        name: "Linux",
        image: "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/Icons8_flat_linux.svg.png?alt=media&token=4b712be6-8861-4869-85f3-ececd5dc8e6f",
      }, 
      {
        name: "Node JS",
        image: "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/node-js.svg?alt=media&token=80d5c7dd-c488-4c37-8b1b-56e79be32f28"
      },     
      {
        name: ".NET",
        image: "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/Microsoft_.NET_logo.svg.png?alt=media&token=42229ca7-3cbd-48f4-81f0-2927e0a87acb",
      },
      {
        name: "Microservices",
        image: "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/microservice.svg?alt=media&token=e54b24f1-b0af-4400-86e2-75e01eca8cb7",
      },
      {
        name: "RabbitMQ",
        image: "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/rabbitmq-logo.svg?alt=media&token=9925cecc-7600-44d3-a055-14822a8b76e3",
      }
    ],
  },
  {
    title: "Database",
    skills: [
      {
        name: "SQL Server",
        image:
          "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/microsoft-sql-server.svg?alt=media&token=bda6b3bf-b5d2-4ab2-a9e6-14468decb661",
      },
      {
        name: "Postgre",
        image:
          "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/Postgresql_elephant.svg.png?alt=media&token=c925c1c8-ca85-4629-a90f-db908872c6b9",
      },
      {
        name: "MongoDB",
        image:
          "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/mongodb.svg?alt=media&token=0adfb881-59e0-4a1c-83da-63863eea8097",
      },
      {
        name: "Redis",
        image:
          "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/redis-logo.svg?alt=media&token=64756199-56e0-4c27-950a-c6727ead0563",
      },
      {
        name: "Firebase",
        image:
          "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/firebase.svg?alt=media&token=74b2105d-3a3c-47b8-8f40-9ee8d49420b3",
      }
    ],
  },
  {
    title: "Frontend",
    skills: [
      {
        name: "React",
        image:
          "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/react-1-logo.svg?alt=media&token=2f4a52c5-9896-4475-a100-cb03f1ea1ecd",
      },      
      {
        name: "HTML",
        image: "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/HTML5_logo_and_wordmark.svg.png?alt=media&token=167d1ffa-7970-4b13-8e89-d4292ed09813",
      },
      {
        name: "CSS",
        image:
          "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/CSS3_logo.svg.png?alt=media&token=358ae46c-db81-4162-9664-28349c56550b",
      },
      {
        name: "JavaScript",
        image:
          "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/Unofficial_JavaScript_logo_2.svg.png?alt=media&token=a82fcba4-bc36-4904-9caa-7773d608515e",
      },
      {
        name: "Bootstrap",
        image:
          "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/Bootstrap_logo.svg?alt=media&token=d6a5e948-cc7a-4a18-a185-123d8226a2f0",
      }
    ],
  },
  {
    title: "Others",
    skills: [
      {
        name: "Git",
        image:
          "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/Git_icon.svg.png?alt=media&token=44e46d34-84b6-4ec9-bfe6-0315dd1b0417",
      },
      {
        name: "GitHub",
        image:
          "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/Octicons-mark-github.svg?alt=media&token=b89e78b4-3872-4a99-9224-10b96fede09d",
      },
      {
        name: "Docker",
        image:
          "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/docker.svg?alt=media&token=8b6055cc-21eb-4393-9903-95fbc6263bf8",
      },
      {
        name: "Pandas",
        image:
          "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/Pandas_mark.svg.png?alt=media&token=e460b9e7-5266-4355-b837-e6214bc70ce9",
      },
      {
        name: "Matplotlib",
        image:
          "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/Matplotlib_icon.svg.png?alt=media&token=9829db5b-feda-4eb6-a8e2-f20e6690959e",
      },
      {
        name: "Postman",
        image:
          "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/postman-icon.svg?alt=media&token=4ba5bda0-24eb-4f51-a31e-b9a6555d1d58",
      },
      {
        name: "Sickit-Learn",
        image:
          "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/Scikit_learn_logo_small.svg.png?alt=media&token=765ec076-9da0-4edf-846f-9153c085bc9e",
      }
    ],
  },
];
export const education = [
  {
    id: 0,
    img: "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/ies-siglo-21-logo.png?alt=media&token=4f558188-3228-4c0c-be7a-7c014a43c70c",
    school: "Colegio Universitario IES",
    date: "2023-Actualiad",
    degree: "Analista en Sistemas",
  },
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/utn-images.png?alt=media&token=a9fc5a27-2474-448f-af6c-1a2f6712c343",
    school: "Universidad Tecnológica Nacional (FRC)",
    date: "2021-2022",
    degree: "Tenicatura Univesitaria en Programación",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/utn-images.png?alt=media&token=a9fc5a27-2474-448f-af6c-1a2f6712c343",
    school: "Universidad Tecnológica Nacional (FRC)",
    date: "2019-2021",
    degree: "Ingenieria Electronica",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/icaroimage.jpg?alt=media&token=9c4f6eff-7516-4c93-b6cc-5611c8fdcfe2",
    school: "ICARO",
    date: "2020",
    degree: "Introduccion a Ciencia de Datos",
    desc: "Conceptos sobre Machine Learning y Data Analytics. Librerías: Pandas, NumPy, Scikit-learn,Matplotlib"
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/icaroimage.jpg?alt=media&token=9c4f6eff-7516-4c93-b6cc-5611c8fdcfe2",
    school: "ICARO",
    date: "2022",
    degree: "Data Analytics",
    desc: "Exploración, analisis, limpieza y visualizacion de datos. Creación de Dashboards y mapas"
  },
  {
    id: 5,
    img: "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/ergoinstitute.png?alt=media&token=d0ac6295-09e7-43fa-befc-47977524c7e6",
    school: "ERGO Institute",
    date: "2021",
    degree: "Big Data & Analytics",
    desc: "Programa de formación en Big Data & Analytics"
  },
  {
    id: 6,
    img: "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/escudo-muni.svg?alt=media&token=f0a6d884-aef6-41c4-ad13-3c411bcbbe3f",
    school: "Programa Municipal Córdoba",
    date: "2018",
    degree: "Robótica",
    desc: "Curso de robótica con tecnología Arduino."
  },
  {
    id: 7,
    img: "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/usa-images.png?alt=media&token=ce2fc57c-c989-4458-82f8-82eefc74d39f",
    school: "Academia Cultura Americana",
    date: "2004-2013",
    degree: "Formacion en Idioma Ingles"    
  }
];

export const experiences = [
  {
    id: 0,
    img: "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/channels4_profile.jpg?alt=media&token=a669d0ad-6f43-4a02-aa71-8947a6fbb0d9",
    role: "Backend Developer",
    company: "Juan Toselli Int. Tours",
    date: "Abril 2022 - Actualidad",
    desc: "Integración de APIs de terceros de productos turísticos. Desarrollo de tareas automatizadas. Diseño de Base de Datos. Limpieza y procesamiento de datos para la creación de Dashboards. /n Desarrollo de sistemas orientado a Microservicios. Soporte del sistema administrativo ",
    skills: [ "C#",".NET","SQL","MongoDB","Redis","Linux","Docker","Microservices","Entity Framework","Javascript","Postman","Visual Studio"]
}
];

export const projects = [      
  {
    id: 0,
    title: "Twitter Bot",
    description:
      "Bot para cuenta de Twitter que responde a usuarios la informacion de dia,fecha y rival de un equipo de futbol en particular. El bot se ejecuta cuando se menciona a la cuenta mas el equipo que se desea obtener información (actualmente deshabilitado por policitas de twiter).",
    image:
      "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/Logo_of_Twitter.svg.png?alt=media&token=1c0731ef-0a4f-4558-a91a-b1eaf9180a0d",
    tags: ["Python", "Tweepy", "BeautifulSoup", "Pandas"],
    category: "personal project",
    github: "https://github.com/jcpereyra97/BotTwitter"
  },
  {
    id: 1,
    title: "Administrador Veterinaria",
    description:
      "Aplicacion realizada para la materia Programación II de la carrera Tecnicatura Universitaria en Programación de la Universidad Tecnológica Nacional. El proyecto consiste en un administrador para una veterinaria con el fin de gestionar clientes/pacientes",
    image:
      "https://firebasestorage.googleapis.com/v0/b/jcpereyra-6754d.appspot.com/o/utn-images.png?alt=media&token=a9fc5a27-2474-448f-af6c-1a2f6712c343",
    tags: ["C#","SQL","POO","WinForms"],
    category: "personal project",
    github: "https://github.com/jcpereyra97/Veterinaria"
  },
];
