import React from 'react'
import { Nav, NavLink, NavContainer, Span, NavLogo, NavItems, GitHubButton, ButtonContainer, MobileIcon, MobileMenu, MobileNavLogo, MobileLink } from './NavBarStyledComponent';
import { Bio } from '../../data/constants.js';
import { useTheme } from 'styled-components';

const Navbar = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    const theme = useTheme()
    return (
    <Nav>
        <NavContainer>
            <NavLogo>Logo</NavLogo>
            <MobileIcon></MobileIcon>
            <NavItems>
                <NavLink href="#about">About</NavLink>
                <NavLink href="#skills">Skills</NavLink>
                <NavLink href="#experience">Experience</NavLink>                
                <NavLink href="#education">Education</NavLink>
                <NavLink href="#projects">Projects</NavLink>
            </NavItems>
            <ButtonContainer>
                <GitHubButton href={Bio.github} target="_blank">Github Profile</GitHubButton>
            </ButtonContainer>
            {
                isOpen &&
                <MobileMenu isOpen={isOpen}>
                    <MobileLink href="#about" onClick={() => {
                    setIsOpen(!isOpen)
                    }}>About</MobileLink>
                    <MobileLink href='#skills' onClick={() => {
                    setIsOpen(!isOpen)
                    }}>Skills</MobileLink>
                    <MobileLink href='#experience' onClick={() => {
                    setIsOpen(!isOpen)
                    }}>Experience</MobileLink>                    
                    <MobileLink href='#education' onClick={() => {
                    setIsOpen(!isOpen)
                    }}>Education</MobileLink>
                    <MobileLink href='#projects' onClick={() => {
                    setIsOpen(!isOpen)
                    }}>Projects</MobileLink>
                    <GitHubButton style={{padding: '10px 16px',background: `${theme.primary}`, color: 'white',width: 'max-content'}} href={Bio.github} target="_blank">Github Profile</GitHubButton>
                </MobileMenu>
            }
        </NavContainer>
    </Nav>
    );
};

export default Navbar;